<template>
  <div>
    <v-expansion-panels
      accordion
      active-class="opened-expansion-panel"
      flat
      hover
      multiple
      v-model="displayedPanels"
    >
      <v-expansion-panel
        v-if="topic.id || links"
        class="border-bottom transparent"
      >
        <v-expansion-panel-header class="font-weight-medium">{{$t('t.LinkedTo')}}
          <v-spacer />
          <v-btn
            v-if="topic.id && !readonly"
            small
            color="primary"
            @click.stop="showPopup = true"
          >{{$t('t.Edit')}}</v-btn>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <document-ref-list
            :action-callback="actionCallback"
            :items.sync="links"
            :linkTarget="linkTarget"
            :docSelector="v => v.id"
            navigate
            :readonly="readonly"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="transparent">
        <v-expansion-panel-header class="font-weight-medium">{{$t('t.AssignedAt')}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <typed-doc-picker
            :selected.sync="assigned"
            :document-types="[toggleValueAssigned]"
            :readonly="computedReadOnly"
            :readonlyList="computedReadOnly"
            @change="addVisibleItem"
            multiple
            chip
            top
            ref="assigned-picker"
          >
            <template v-slot:append>
              <topic-toggle-filter
                :toggle-value.sync="toggleValueAssigned"
                @toggle-type-click="$refs['assigned-picker'].handleClick()"
              > </topic-toggle-filter>
            </template>
          </typed-doc-picker>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="border-bottom transparent">
        <v-expansion-panel-header class="font-weight-medium">{{$t('t.VisibleBy')}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <typed-doc-picker
            :selected.sync="visible"
            :document-types="[toggleValueVisible]"
            :readonly="computedReadOnly"
            :readonlyList="computedReadOnly"
            @item-closed="removeAssignedItem"
            multiple
            chip
            top
            ref="visible-picker"
          >
            <template v-slot:append>
              <topic-toggle-filter
                :toggle-value.sync="toggleValueVisible"
                @toggle-type-click="$refs['visible-picker'].handleClick()"
              > </topic-toggle-filter>
            </template>
          </typed-doc-picker>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <search-link
      v-model="showPopup"
      v-on="$listeners"
      :link-target="linkTarget"
    />
  </div>
</template>

<script>
import { CacheType } from '@/wasm/pkg'
export default {
  components: {
    DocumentRefList: () => import('@/components/document-ref-list'),
    SearchLink: () => import('@/pages/search/search-link'),
    TopicToggleFilter: () => import('./topic-toggle-filter'),
    TypedDocPicker: () => import('@/components/typed-doc-picker')
  },
  data () {
    return {
      externalUserCacheType: CacheType.UserRef,
      showPopup: false,
      toggleValueVisible: 'roles',
      toggleValueAssigned: 'users',
      displayedPanels: [0, 1, 2, 3]
    }
  },
  methods: {
    actionCallback () {
      this.$emit('update-links')
    },
    addVisibleItem (e) {
      if (!this.visible.find(i => i.type === e.type && i.id === e.id)) {
        this.visible.push(e)
      }
    },
    removeAssignedItem (e) {
      const index = this.assigned.findIndex(i => e.id === i.id)
      if (index > -1) {
        const clonedList = this.lodash.cloneDeep(this.assigned)
        this.assigned.splice(index, 1)
        this.assigned = clonedList
      }
    }
  },
  computed: {
    canUpdate () {
      return !this.computedReadOnly && (!this.topic?.id || this.topic?.createBy === this.$store.getters.currentUser.id)
    },
    computedReadOnly () {
      return this.readonly
    },
    links: {
      get () {
        return this.topic.links ?? []
      },
      set (links) {
        this.$emit('update:topic', Object.assign({}, this.topic, { links }))
      }
    },
    assigned: {
      get () {
        return this.topic.assigned ? this.topic.assigned : []
      },
      set (assigned) {
        this.$emit('update:topic', Object.assign({}, this.topic, { assigned }))
      }
    },
    visible: {
      get () {
        return this.topic.visible ? this.topic.visible : []
      },
      set (visible) {
        this.$emit('update:topic', Object.assign({}, this.topic, { visible }))
      }
    },
    linkTarget () {
      return this.topic.id ? { id: this.topic.id, type: 'topics' } : null
    }
  },
  props: {
    readonly: Boolean,
    topic: Object,
    userId: String
  }
}
</script>

<style lang="stylus" scoped>
.border-bottom
  border-bottom solid 1px var(--bg-plain) !important

.opened-expansion-panel > .v-expansion-panel-header
  border var(--bg-translucid)
  min-height 48px
</style>
